<template>
  <article>
    <p class="menu-label">
      <span v-if="value.vendorName === ''"
        class="title is-6">
        Select Vendor
      </span>
      <span v-else
        class="title is-6">
        {{ value.vendorName }}
      </span>
    </p>
    <div>
      <div class="columns">
        <div class="column field">
          <div class="field">
            <label class="label">Vendor ABN</label>
          </div>
          <div class="control has-icons-right">
            <the-mask v-if="$company.info.countryCode === 'AU'"
              id="abn"
              :class="{ 'is-danger' : $v.creditDetail.vendorAbn.$error && isEntity && !isReadOnly }"
              name="abn"
              ref="abn"
              class="input abn"
              :masked="value.vendorAbn.length === 0 ? true : false"
              :disabled="isReadOnly || !isEntity"
              v-model="value.vendorAbn"
              placeholder="Enter Vendor ABN"
              :mask="['## ### ### ###']" />
            <input v-else
              v-model="value.vendorAbn"
              id="abn"
              ref="abn"
              class="input is-uppercase"
              maxlength="12"
              :disabled="isReadOnly || !isEntity">
            <span v-if="value.vendorAbn && !$v.creditDetail.vendorAbn.$error && $v.creditDetail.vendorAbn.validAbn && $v.creditDetail.vendorAbn.minLength && isEntity && $company.info.countryCode === 'AU'"
              class="icon has-text-success is-right">
              <i class="mdi mdi-check mdi-18px" />
            </span>
            <span class="help is-danger"
              v-if="$v.creditDetail && !$v.creditDetail.vendorAbn.minLength && isEntity && !isReadOnly">ABN must be at least {{ $v.creditDetail.vendorAbn.$params.minLength.min }} letters.</span>
            <span class="help is-danger"
              v-else-if="$v.creditDetail && !$v.creditDetail.vendorAbn.validAbn && isEntity && !isReadOnly">ABN is invalid.</span>
            <span class="help is-danger"
              v-if="value.vendorAbn === '' && isEntity && !isReadOnly && $company.info.isWithHold">
              This vendor does not have an ABN. You will be required to with hold {{ $userInfo.withHoldingTax }}% of the invoice.
            </span>
          </div>
          <hr>
          <div class="control">
            <button class="button is-primary"
              @click="addAttachments()"
              :disabled="isAttachmentDisabled">
              <span class="file-icon">
                <i class="mdi mdi-upload mdi-24px" />
              </span>
              <span v-if="!isReadOnly">Add Attachments</span>
              <span v-else> Show Attachments</span>
            </button>
          </div>
        </div>
        <div class="column field">
          <div class="control">
            <div class="field">
              <label class="label is">Credit Date</label>
            </div>
            <div class="field is-narrow is-pulled-left">
              <v-date-picker v-model="value.creditDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="value.creditDate = $filters.fromISOWithCurrentTime($event.toISOString())"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <div class="control">
                        <input type="text"
                          class="input"
                          :class="{'is-danger' : $v.creditDetail.creditDate.$invalid && isEntity && !isReadOnly}"
                          :disabled="isReadOnly || !isEntity"
                          placeholder="Select Credit Date"
                          :value="inputValue"
                          v-on="inputEvents">
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                      <span class="help is-danger"
                        v-if="!$v.creditDetail.creditDate.required && isEntity && !isReadOnly">Credit Date is required.
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="control">
            <div class="field">
              <label class="label">Credit Number</label>
            </div>
            <div class="control">
              <p class="control">
                <input class="input"
                  :class="{'is-static' : value.vendorId === '', 'is-danger' : $v.creditDetail.creditNo.$error && isEntity && !isReadOnly}"
                  placeholder="Enter Credit Number"
                  :disabled="isReadOnly || !isEntity"
                  v-model="value.creditNo">
              </p>
              <span class="help is-danger"
                v-if="!$v.creditDetail.creditNo.required && isEntity && !isReadOnly">Credit Number is required.
              </span>
            </div>
          </div>
        </div>
        <dealer-credit-entry-attachments-modal v-if="isDealerCreditEntryAttachmentsActive"
          :active.sync="isDealerCreditEntryAttachmentsActive"
          :credit-lines="creditLines"
          :read-only="isReadOnly"
          @cancel="closeAttachment($event)"
          :show-icon="true"
          :value="value">
          <p slot="text-title">Attachments</p>
        </dealer-credit-entry-attachments-modal>
      </div>
    </div>
  </article>
</template>
<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { mask } from 'vue-the-mask'
import { TheMask } from 'vue-the-mask'
// import _cloneDeep from 'lodash/cloneDeep'
import DealerCreditEntryAttachmentsModal from './DealerCreditEntryAttachmentsModal'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'DealerCreditEntryDetailValue',
  inject: ['$vv'],
  components: {
    DealerCreditEntryAttachmentsModal,
    TheMask
  },
  directives: {
    mask
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    isReadOnly: Boolean,
    isEntity: Boolean,
    creditLines: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDealerCreditEntryAttachmentsActive: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    creditedLines() {
      return this.creditLines.filter((row) => row.credQty > 0)
    },
    isAttachmentDisabled() {
      if (this.isReadOnly) {
        return true
      }

      if (!this.isEntity) {
        return true
      }

      if (this.creditedLines.length == 0) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    addAttachments() {
      this.isDealerCreditEntryAttachmentsActive = true
    },
    closeAttachment(value) {
      this.isDealerCreditEntryAttachmentsActive = value
    }
  }
}
</script>
