<template>
  <article>
    <!-- <p class="title is-6"> Return Total: $ {{ returnTotalAmount | formatNumber($userInfo.locale) }}</p>
    <p class="title is-6"> GST: $ {{ gstAmount | formatNumber($userInfo.locale) }}</p> -->
    <div style="overflow-y: auto; height: 400px;">
      <subtitle-totals class="title is-6"
        :labels="['Return Total', 'GST']"
        :totals="[returnTotalWithOtherFee, returnTotalGst]"
        :shows="[true, true]" />
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :total-rows="!value ? 0 : value.length"
        :show-pagination="false">
        <tr v-for="(item,index) in value"
          :key="item.dealerCreditID"
          :class="{ 'is-selected' : selectedRow === index }">
          <td>
            <div class="has-vertical-middle">{{ item.poNumber }}</div>
          </td>
          <td>
            <div class="has-vertical-middle quote-col">
              <span v-if="item.quoteDeleted"
                style="padding-right: 3px"
                class="icon has-text-danger tooltip is-tooltip-danger"
                data-tooltip="Quote has been deleted.">
                <i class="mdi mdi-alert mdi-18px" />
              </span>
              {{ item.quoteNo }}
            </div>
          </td>
          <td>
            <div class="has-text-right">{{ item.invNo }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ $filters.formatDateTimezone(item.invDate, $userInfo.locale) }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.recd }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.returnNo }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ $filters.formatDateTimezone(item.returnDate, $userInfo.locale) }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.retd }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.cred }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.qtyLeft }}</div>
          </td>
          <td width="5%">
            <vue-numeric :minus="false"
              placeholder="Qty"
              class="input has-text-right"
              :min="0"
              :disabled="isReadOnly || value[index].creditReturnID === emptyGuid || (selectedPO != item.purchaseOrderID && selectedPO != null)"
              @blur="calculateTotal()"
              @input="checkIsSamePO(item.purchaseOrderID, item.credQty)"
              v-model.number="item.credQty" />
          </td>
          <td width="12%">
            <vue-numeric v-if="!isReadOnly"
              :minus="false"
              placeholder="Amount Required"
              class="input has-text-right"
              :min="0.00"
              :disabled="isReadOnly || value[index].creditReturnID === emptyGuid || (selectedPO != item.purchaseOrderID && selectedPO != null)"
              :precision="2"
              @blur="calculateTotal()"
              @input="checkIsSamePO(item.purchaseOrderID, item.credQty)"
              v-model.number="item.ExcGST" />
            <vue-numeric v-else
              :minus="false"
              placeholder="Amount Required"
              class="input has-text-right"
              :min="0.00"
              :disabled="isReadOnly || value[index].creditReturnID === emptyGuid || (selectedPO != item.purchaseOrderID && selectedPO != null)"
              :precision="2"
              v-model.number="item.amtExcGST" />
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.partNo }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.script }}</div>
          </td>
          <!-- <td>
            <div class="has-vertical-middle">{{ item.clearQuantity }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.clearValue }}</div>
          </td> -->
          <td>
            <div class="has-vertical-middle">{{ item.reason }}</div>
          </td>
          <td width="10%">
            <vue-numeric
              :minus="false"
              placeholder="Fee Required"
              class="input has-text-right"
              :min="0.00"
              @blur="calculateTotal()"
              @input="checkIsSamePO(item.purchaseOrderID, item.credQty)"
              :disabled="isReadOnly || value[index].creditReturnID === emptyGuid || (selectedPO != item.purchaseOrderID && selectedPO != null)"
              :precision="2"
              v-model.number="item.otherFee" />
          </td>
          <td class="has-text-centered">
            <a v-if="!item.otherReason"
              class="tag is-primary">
              <span>
                <i class="mdi  mdi-file-document"
                  @click="addOtherReason(index)" /></span>
            </a>
            <a v-else
              class="tag is-success"
              :content="item.otherReason"
              v-tippy="{ enabled: true }">
              <span> <i class="mdi  mdi-file-document"
                @click="addOtherReason(index)" /></span>
            </a>
          </td>
        </tr>
        <template slot="empty">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-36px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </template>

        <!-- <tfoot>
          <tr>
            <th class="has-text-left">Banking Total </th>
            <th class="has-text-right has-text-success">{{ value | formatCurrency($userInfo.locale) }}</th>
          </tr>
        </tfoot> -->
      </bulma-table>
      <delaer-credit-entry-other-reason-modal v-if="isOtherReasonModelActive"
        :selected-item="selectedReason"
        :active.sync="isOtherReasonModelActive"
        @cancel="closeOtherModal($event)"
        :value="value">
        <p slot="text-title">Add Notes</p>
      </delaer-credit-entry-other-reason-modal>
    </div>
  </article>
</template>

<script>
// import DealerCreditEntryService from './BankUndepositedFundService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import BulmaTable from '@/components/BulmaTable'
import { DealerCreditEntryColumns } from './columns'
import VueNumeric from '@/components/VueNumeric'
import _cloneDeep from 'lodash/cloneDeep'
import SubtitleTotals from '@/components/SubtitleTotals'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import Guid from '@/components/Guid'
import DelaerCreditEntryOtherReasonModal from './DelaerCreditEntryOtherReasonModal'

export default {
  name: 'DealerCreditEntryDetail',
  components: {
    DelaerCreditEntryOtherReasonModal,
    BulmaTable,
    SubtitleTotals,
    VueNumeric
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    vendorDetail: {
      type: Object
    },
    isReadOnly: Boolean,
    isEntity: Boolean,
    value: null
  },
  data() {
    return {
      creditDetail: _cloneDeep(this.vendorDetail),
      isTableLoading: false,
      totalRows: 0,
      returnTotalAmount: 0.0,
      gstAmount: 0.0,
      selectedPO: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      selectedRow: null,
      isOtherReasonModelActive: false,
      selectedReason: null,
      otherFeeTotalAmount: 0.00,
      returnTotalExGst: 0.00,
      returnTotalWithOtherFee: 0.00,
      otherGstAmountTotal: 0.00,
      returnTotalGst: 0.00
    }
  },
  computed: {
    $v() {},
    columns() {
      return DealerCreditEntryColumns
    },
    emptyGuid() {
      return Guid.empty()
    }
  },
  watch: {
    isReadOnly: function (newVal, oldVal) {
      if (!newVal) {
        this.returnTotalAmount = 0.0
        this.gstAmount = 0.0
        this.otherFeeTotalAmount = 0.0
        this.returnTotalExGst = 0.0
        this.returnTotalWithOtherFee = 0.00
        this.returnTotalGst = 0.0

      }
    },
    value: function (newVal, oldVal) {
      if (newVal) {
        this.returnTotalAmount = 0.0
        this.gstAmount = 0.0
        this.otherFeeTotalAmount = 0.0
        this.returnTotalExGst = 0.00
        this.returnTotalWithOtherFee = 0.00
        this.returnTotalGst = 0.0
      }
    },
    'vendorDetail.vendorAbn': function (newValue) {
      this.calculateTotal()
    }
  },
  async created() {
    // this.getData()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    calculateTotal() {
      this.returnTotalAmount = 0.0
      this.otherFeeTotalAmount = 0.0
      this.returnTotalExGst = 0.00
      this.returnTotalWithOtherFee = 0.00
      this.returnTotalGst = 0.0
      this.gstAmount = 0.0

      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i].credQty > 0) {
        // before FQ-708 amount based on INC GST on CreditRecipt
          this.value[i].totalAmount =
            (roundAwayFromZero(this.value[i].inclGST, 2) / roundAwayFromZero(this.value[i].recd, 2)) * roundAwayFromZero(this.value[i].credQty, 2)
          this.value[i].invlGst = roundAwayFromZero(this.value[i].totalAmount, 2)
          this.returnTotalAmount = roundAwayFromZero(this.returnTotalAmount + this.value[i].totalAmount, 2)

        // FQ-708 change amount based on other fee and exc gst on credit return
          if(this.value[i].credQty == this.value[i].recd) {
            this.value[i].totalExcAmount = roundAwayFromZero(this.value[i].returnAmount , 2)
          } else {
            this.value[i].totalExcAmount =
              (roundAwayFromZero(this.value[i].returnAmount, 2) / roundAwayFromZero(this.value[i].recd, 2)) * roundAwayFromZero(this.value[i].credQty, 2)
          }

          this.otherFeeTotalAmount = roundAwayFromZero(this.otherFeeTotalAmount + this.value[i].otherFee, 2)
          this.value[i].ExcGST = roundAwayFromZero(this.value[i].totalExcAmount, 2)
          this.returnTotalExGst = roundAwayFromZero(this.returnTotalExGst + this.value[i].totalExcAmount, 2)

          this.value[i].amtWithOtherFee = roundAwayFromZero(this.value[i].totalExcAmount - this.value[i].otherFee, 2)
          this.value[i].gstAmount = roundAwayFromZero(this.value[i].amtWithOtherFee * this.value[i].companyGstRate / 100 ,2)
          this.value[i].amtWithOtherFeeIncGst = roundAwayFromZero(this.value[i].amtWithOtherFee + this.value[i].gstAmount, 2)
          this.returnTotalWithOtherFee = roundAwayFromZero(this.returnTotalWithOtherFee + this.value[i].amtWithOtherFeeIncGst, 2)
        } else {
          this.value[i].invlGst = 0.0
          this.value[i].ExcGST = 0.0
        }
      }
      if (this.value.length > 0) {
        if (this.$company.info.isGst && this.value[0].gstRate !== 0) {
          this.returnTotalGst = roundAwayFromZero(this.returnTotalWithOtherFee / this.value[0].gstRate, 2)
        }

        this.value.forEach((i) => (i.returnTotalAmount = this.returnTotalAmount))
        this.value.forEach((i) => (i.returnTotalGst = this.returnTotalGst))
        this.value.forEach((i) => (i.otherFeeTotalAmount = this.otherFeeTotalAmount))
        this.value.forEach((i) => (i.returnTotalExGst = this.returnTotalExGst))
        this.value.forEach((i) => (i.returnTotalWithOtherFee = this.returnTotalWithOtherFee))
      }
      // if(this.vendorDetail.vendorAbn === ''){
      // this.gstAmount = roundAwayFromZero(this.returnTotalAmount * 49 / 100, 2)
      // this.value.forEach(i => (i.returnTotalAmount = this.returnTotalAmount))
      // this.value.forEach(i => (i.gstAmount = this.gstAmount))
      // }
      // else {
      //   if(this.value.length > 0){
      // this.gstAmount = roundAwayFromZero(this.returnTotalAmount / this.value[0].gstRate, 2)
      // this.value.forEach(i => (i.returnTotalAmount = this.returnTotalAmount))
      // this.value.forEach(i => (i.gstAmount = this.gstAmount))
      //   }
      // }
    },
    async getData() {
      this.isTableLoading = true
      // this.retrieveFilter()
      this.activeItems = await DealerCreditEntryService.getEntitySummaries(this.filter)
      // this.totalRows = this.entity.length > 0 ? this.entity[0].totalRows : 0
      this.isTableLoading = false
      this.$hideSpinner()
    },
    checkIsSamePO(poID, credQty) {
      if (this.selectedPO === null && credQty !== 0) {
        this.selectedPO = poID
      } else {
        let find = this.value.find((i) => i.purchaseOrderID === poID && i.credQty !== 0)
        if (find === undefined) {
          this.selectedPO = null
        }
      }
    },
    addOtherReason(index) {
      this.selectedReason = index
      this.isOtherReasonModelActive = true
    },
    closeOtherModal() {
      this.selectedReason = null
      this.isOtherReasonModelActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-col {
  min-width: 6.5em;
}
</style>
